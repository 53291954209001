import React, { useState, useEffect } from "react";
import { Row, Col, Button, Menu, Input, Form, Table, Checkbox, Dropdown } from "antd";
import { SearchOutlined, EyeOutlined, ArrowLeftOutlined, ArrowRightOutlined, FileDoneOutlined, DollarOutlined, MoreOutlined } from "@ant-design/icons";
import moment from 'moment';

import api from '../../services/api';
import { useStateValue } from '../../state';
import DrawerPagamento from './drawerPagamento';
import { compararDatas, info } from "../../services/funcoes";
import { drawerActions, listagemActions } from "../../actions";
import { FormatFone, MaskFormat, FormatNumber } from '../../ValueObjects';
import { BreadcrumbPage, TabelaDados, Data } from "../../components";
import ModalDetalheNotaFiscal from "../notaFiscal/modalDetalheNotaFiscal";

export default function PagamentoParcela() {

    const [{ manutencao }, dispatch] = useStateValue();
    const [listaParcelasFornecedor, setListaParcelasFornecedor] = useState(false);
    const [listaParcelasPagar, setListaParcelasPagar] = useState([]);
    const [dadosFornecedor, setDadosFornecedor] = useState({});
    const [listaTotalizadores, setListaTotalizadores] = useState({});
    const [formulario] = Form.useForm();
    const [alterarValorPago, setAlterarValorPago] = useState(true);
    const [formParametros] = Form.useForm();
    const [listaParcelas, setListaParcelas] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [openModalDetalhes, setOpenModalDetalhes] = useState(false);
    const [dadosModalNota, setDadosModalNota] = useState({});
    const [dadosDetalhesNota, setDadosDetalhesNota] = useState({});
    function selecionarParcelas(keys) {
        setSelectedRowKeys(keys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: selecionarParcelas,
    };

    useEffect(() => {
        if (!!dadosFornecedor.pes_id) {
            api.get(`Parcela/ListarParcelas?PagarOuReceber=0&IdPessoa=${dadosFornecedor.pes_id}&StatusParcela=0,1&Estornado=false&PageSize=1000&Order=prc_datavencimento`).then(res => {
                listarParcelas(res.data.items);
            });
        }
    }, [dadosFornecedor]);

    function listarParcelas(dados) {
        let parcelasPagar = [];
        let keys = [];

        if (listaParcelasPagar.length > 0) {
            listaParcelasPagar.map((parcela) => {
                keys.push(parcela.key);
            });
            setSelectedRowKeys(keys);
        }

        dados.forEach((item) => {
            item.key = item.cpr_id.toString().concat(item.prc_id);
            item.vencida = compararDatas(item.prc_datavencimento, null, '<');
            item.valorOriginal = parseFloat(item.prc_valor);
            item.valorDesconto = 0.00;
            item.valorJuros = 0.00;
            item.valorMulta = 0.00;
            item.prc_valor = parseFloat((item.prc_valor - item.valorQuitado).toFixed(2));
            item.valorPago = parseFloat(item.prc_valor);
            parcelasPagar.push(item);
        });
        setListaParcelas(parcelasPagar);
    };

    function selecionarVencidas(e) {
        let keys = [];
        if (e.target.checked) {
            listaParcelas.map((item) => {
                if (compararDatas(item.prc_datavencimento, null, "<")) {
                    keys.push(item.key);
                }
            });
        }
        setSelectedRowKeys(keys);
    };

    function menuTable(dados) {
        return (
            <Menu className="text-right">
                <Menu.Item key="1">
                    <Button type="link" icon={<FileDoneOutlined />} onClick={() => {
                        let keys = [...selectedRowKeys];
                        if (selectedRowKeys.indexOf(dados.key) < 0) {
                            keys.push(dados.key);
                            setSelectedRowKeys(keys);
                        }
                        pagarSelecionados(listaParcelas, keys);
                    }}> Quitar Parcela </Button>
                </Menu.Item>
                <Menu.Item key="2">
                    <Button type="link" icon={<EyeOutlined />} onClick={() => {
                        visualizarNota(dados);
                        //showDrawerExtra(dados);
                    }}> Detalhes da Nota </Button>
                </Menu.Item>
            </Menu>
        )
    };
    async function visualizarNota(dados) {
        let retorno = [];
        let vendedor = [];
        if (!!dados.ntf_id) {
            retorno = await api.get(`NotaFiscal/ListarNotaFiscal?IdNotaFiscal=${dados.ntf_id}`);
            if (retorno.status === 200) {
                retorno = retorno.data;
                if (!!retorno.ven_id) {
                    vendedor = await api.get(`Vendedor/RetornaVendedor?idVendedor=${retorno.ven_id}`);
                    if (vendedor.status === 200) {
                        retorno.nomeVendedor = vendedor.data.pes_nome;
                    }
                }
                retorno.itensNf.forEach((item) => {
                    let totalItemSemDesconto = parseFloat((item.nfi_qtde * item.nfi_valorunitario)).toFixed(2);
                    item.perc_desconto = parseFloat((item.nfi_valordesconto / (totalItemSemDesconto)) * 100).toFixed(2);
                    let valorDesconto = item.nfi_valordesconto;
                    item.valor_item_desconto = parseFloat(totalItemSemDesconto - valorDesconto).toFixed(2);
                });
                setDadosDetalhesNota(retorno);
                setOpenModalDetalhes(true);
            }
        }
    };


    async function showDrawerExtra(dados) {
        let retorno = [];
        let vendedor = [];
        if (!!dados.ntf_id) {
            retorno = await api.get(`NotaFiscal/ListarNotaFiscal?IdNotaFiscal=${dados.ntf_id}`);
            if (retorno.status === 200) {
                retorno = retorno.data;
                if (!!retorno.ven_id) {
                    vendedor = await api.get(`Vendedor/listarVendedorCodigo?codigoVendedor=${retorno.ven_id}`);
                    if (vendedor.status === 200) {
                        retorno.nomeVendedor = vendedor.data.pes_nome;
                    }
                }
                retorno.itensNf.forEach((item) => {
                    let totalItemSemDesconto = parseFloat((item.nfi_qtde * item.nfi_valorunitario)).toFixed(2);
                    item.perc_desconto = parseFloat((item.nfi_valordesconto / (totalItemSemDesconto)) * 100).toFixed(2);
                    let valorDesconto = item.nfi_valordesconto;
                    item.valor_item_desconto = parseFloat(totalItemSemDesconto - valorDesconto).toFixed(2);
                });

                setDadosModalNota(retorno);
                setOpenModalDetalhes(true);
            }
        }
    };

    useEffect(() => {
        dispatch({ type: listagemActions.CHANGE, data: { ordem: '+pes_nome' } });

    }, []);

    useEffect(() => {
        let lista = {};
        lista.valorTotal = 0;
        lista.valorTotalParcelas = 0;
        lista.valorTotalJuros = 0;
        lista.valorDesconto = 0;
        lista.valorAPagar = 0;
        lista.valorMulta = 0;
        let valorRecebido = 0;
        if (listaParcelasPagar.length > 0) {
            listaParcelasPagar.map(item => {
                let valorTotal = parseFloat((!!item.valorOriginal ? item.valorOriginal : 0)) - parseFloat((!!item.valorQuitado ? item.valorQuitado : 0));
                lista.valorTotalParcelas += parseFloat(item.valorOriginal);
                lista.valorTotalJuros += parseFloat(item.valorJuros);
                lista.valorDesconto += parseFloat(item.valorDesconto);
                lista.valorMulta += parseFloat(item.valorMulta > 0 ? item.valorMulta : 0);
                lista.valorTotal += valorTotal;
                lista.valorAPagar += parseFloat((valorTotal + parseFloat(item.valorMulta) + parseFloat(item.valorJuros) - parseFloat(item.valorDesconto)).toFixed(2))
                valorRecebido += parseFloat(!!item.valorPago ? item.valorPago : 0);
            });
            if (alterarValorPago) {
                formulario.setFieldsValue({ valorPago: parseFloat((valorRecebido).toFixed(2)) });
            }
            formulario.setFieldsValue({ valorMulta: parseFloat(lista.valorMulta.toFixed(2)), valorDesconto: parseFloat(lista.valorDesconto.toFixed(2)) });
            calcularSobra(lista);
        }
    }, [listaParcelasPagar]);

    function calcularSobra(lista = { ...listaTotalizadores }) {
        let sobra = parseFloat((parseFloat(!!formulario.getFieldValue("valorPago") ? formulario.getFieldValue("valorPago") : 0) - parseFloat(lista.valorAPagar)).toFixed(2));
        lista.restantePagar = 0;
        lista.troco = 0;
        if (sobra < 0) {
            lista.restantePagar = (parseFloat(sobra) * -1).toFixed(2);
        } else {
            lista.troco = parseFloat(sobra);
        }
        setAlterarValorPago(true);
        setListaTotalizadores(lista);
    };

    function calcularPagamentoParcial() {
        let valorInformado = parseFloat(formulario.getFieldValue("valorPago"));
        if (valorInformado > 0) {
            let listaTemp = [...listaParcelasPagar];
            for (const item of listaTemp) {
                let valorTotal = parseFloat((!!item.valorOriginal ? item.valorOriginal : 0)) - parseFloat((!!item.valorQuitado ? item.valorQuitado : 0));
                let valorParcela = parseFloat((valorTotal - parseFloat(item.valorDesconto) + parseFloat(item.valorJuros) + parseFloat(item.valorMulta)).toFixed(2));
                if (valorInformado > valorParcela) {
                    item.prc_valor = valorParcela;
                    item.valorPago = valorParcela;
                } else {
                    item.prc_valor = valorInformado;
                    item.valorPago = valorInformado;
                }
                valorInformado -= item.prc_valor;
            }
            setAlterarValorPago(false);

            setListaParcelasPagar(listaTemp);
        } else {
            calcularSobra();
        }
    };

    function pagarSelecionados(listaParcelas, selectedRowKeys) {
        if (selectedRowKeys.length === 0) {
            info("Selecione uma parcela!");
            return;
        }
        let listaTemp = [...listaParcelasPagar];

        listaParcelas.map(parcela => {
            if (selectedRowKeys.indexOf(parcela.key) >= 0 && listaTemp.filter((item) => { return item.key === parcela.key }).length === 0) {
                listaTemp.push(parcela);
            }
        });

        setListaParcelasPagar(listaTemp);
        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
    };

    function limparListas() {
        setListaParcelasFornecedor(false);
        setDadosFornecedor({});
        setSelectedRowKeys([]);
        setListaParcelasPagar([]);
        setListaTotalizadores({});
        formulario.resetFields();
    };

    function filtrarPeriodo() {
        let valor = formParametros.getFieldValue().pesquisa;
        if (!!formParametros.getFieldValue().dataInicial && !!formParametros.getFieldValue().dataFinal) {
            let dataInicial = moment(formParametros.getFieldValue().dataInicial).format('YYYY-MM-DD');
            let dataFinal = moment(formParametros.getFieldValue().dataFinal).format('YYYY-MM-DD');
            api.get(`Parcela/ListarParcelas?filtro=${!!valor ? valor : ''}&Estornado=false&DataInicial=${dataInicial}&DataFinal=${dataFinal}&PagarOuReceber=0&StatusParcela=0,1&PageSize=1000&Order=prc_datavencimento`).then(res => {
                listarParcelas(res.data.items);
            });
            setListaParcelasFornecedor(true);
            setDadosFornecedor({});
        }
    };

    function filtrarFornecedor() {
        let valor = formParametros.getFieldValue().pesquisa;
        dispatch({ type: listagemActions.CHANGE, data: { filtro: '', outrosFiltros: `&Filtro=${valor}` } });
        setListaParcelasFornecedor(false);

        if (!!formParametros.getFieldValue().dataInicial && !!formParametros.getFieldValue().dataFinal) {
            filtrarPeriodo();
        }
    };

    return (
        <div className="p-10">
            <Row align="middle" justify="space-between" className="col-pagina-breadcrumb">
                <Col className="col-w-100">
                    <BreadcrumbPage />
                </Col>
            </Row>
            <Form layout="vertical" form={formParametros}>
                <Row gutter={[8, 0]} justify="end">
                    <Col xs={24} sm={17} md={18} lg={19} xl={10} xxl={13}>
                        <Form.Item label="Localizar Fornecedor" name="pesquisa">
                            <Input.Search placeholder="Pesquisa por Fornecedor... (Nome, CPF, CNPJ)" onSearch={filtrarFornecedor} allowClear />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={7} md={6} lg={5} xl={4} xxl={3} className="tt-5">
                        <Button icon={<SearchOutlined />} block onClick={() => dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } })} className="t-mob-573">
                            Buscar por Cód. Barras
                        </Button>
                    </Col>
                    <Col xs={24} sm={12} md={9} lg={9} xl={4} xxl={3} className="tt-5">
                        <Data label="Data Vencimento Inicial" name="dataInicial" onBlur={filtrarPeriodo} />
                    </Col>
                    <Col xs={24} sm={12} md={9} lg={9} xl={4} xxl={3}>
                        <Data label="Data Vencimento Final" name="dataFinal" onBlur={filtrarPeriodo} />
                    </Col>
                    <Col xs={24} sm={6} md={6} lg={6} xl={2} xxl={2} className="tt-5">
                        <Button type="primary" block icon={<SearchOutlined />} onClick={filtrarPeriodo} className="t-mob">
                            Aplicar
                        </Button>
                    </Col>
                </Row>
            </Form>
            {!listaParcelasFornecedor &&
                <div className="tabela">
                    <TabelaDados url="fornecedor/listar" paginacao={true} colunas={
                        [
                            {
                                title: 'Fornecedor',
                                align: 'left',
                                render: ({ pes_nome }) => (
                                    <div>
                                        <b>{pes_nome}</b>
                                    </div>
                                ),
                            }, {
                                title: 'CPF/CNPJ',
                                width: 140,
                                render: ({ pef_cpf, pej_cnpj }) => (
                                    <div>
                                        <b>{!!pef_cpf ? MaskFormat(pef_cpf, '', true) : MaskFormat(pej_cnpj, '', true)}</b>
                                    </div>
                                ),
                            }, {
                                title: 'Telefone',
                                width: 125,
                                render: ({ psc_numero }) => (
                                    <div>
                                        <b>{FormatFone(psc_numero)}</b>
                                    </div>
                                ),
                            },
                            {
                                dataIndex: '',
                                key: 'x',
                                align: 'center',
                                width: 65,
                                fixed: 'right',
                                render: (record) => (
                                    <div>
                                        <Button type="primary" icon={<ArrowRightOutlined />} onClick={() => {
                                            setDadosFornecedor(record);
                                            setListaParcelasFornecedor(true);
                                        }} />
                                    </div>
                                ),
                            },
                        ]
                    } />
                </div>
            }
            {listaParcelasFornecedor &&
                <div className="m-t-8">
                    <Form layout="vertical">
                        <Row align="middle" justify="space-between" gutter={[8, 8]}>
                            <Col xs={24} sm={6} md={5} lg={4} xl={3} xxl={2}>
                                <Button icon={<ArrowLeftOutlined />} htmlType="submit" onClick={() => {
                                    setListaParcelasFornecedor(false);
                                    if (listaParcelasPagar.length === 0) {
                                        setDadosFornecedor({});
                                    }
                                }} block>
                                    Voltar
                                </Button>
                            </Col>
                            <Col xs={24} sm={18} md={12} lg={14} xl={16} xxl={18} className="text-center">
                                Parcelas em Aberto do Fornecedor <br />
                                <b className="f-16">{dadosFornecedor.pes_nome || "VÁRIOS"}</b>
                            </Col>
                            <Col xs={24} sm={24} md={7} lg={6} xl={5} xxl={4}>
                                <Button onClick={() => pagarSelecionados(listaParcelas, selectedRowKeys)} icon={<DollarOutlined />} type="primary" htmlType="submit" block>
                                    Pagar Todos os Selecionados
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                    <div className="tabela m-t-8">
                        <Checkbox onChange={selecionarVencidas}>
                            Selecionar Vencidas
                        </Checkbox>
                        <Table dataSource={listaParcelas} columns={[
                            {
                                title: 'Nº Doc.',
                                width: 130,
                                render: ({ cpr_numerodocumento }) => (
                                    <div>
                                        <b>{cpr_numerodocumento || '--'}</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Nº Parcela',
                                align: 'left',
                                width: 80,
                                render: ({ prc_numeroparcela }) => (
                                    <div>
                                        <b>{prc_numeroparcela}</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Fornecedor',
                                width: 330,
                                align: 'left',
                                render: ({ pes_nome }) => (
                                    <div>
                                        <b>{pes_nome}</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Emissão',
                                width: 85,
                                render: ({ cpr_datainclusao }) => (
                                    <div>
                                        <b>{moment(cpr_datainclusao).format('DD/MM/YYYY')}</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Vencimento',
                                width: 80,
                                render: ({ prc_datavencimento, vencida }) => (
                                    <div>
                                        <b className={vencida ? "c-red" : ""}>{moment(prc_datavencimento).format('DD/MM/YYYY')}</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Vlr. Original (R$)',
                                align: 'right',
                                render: ({ valorOriginal }) => (
                                    <div>
                                        <b>
                                            {FormatNumber(!!valorOriginal ? valorOriginal : 0, true)}
                                        </b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Vlr. Pago (R$)',
                                align: 'right',
                                render: ({ valorQuitado }) => (
                                    <div>
                                        <b>
                                            {FormatNumber(!!valorQuitado ? valorQuitado : 0, true)}
                                        </b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Vlr. A Pagar (R$)',
                                align: 'right',
                                render: ({ valorPago }) => (
                                    <div>
                                        <b>
                                            {FormatNumber(!!valorPago ? valorPago : 0, true)}
                                        </b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Ações',
                                dataIndex: '',
                                key: 'x',
                                align: 'center',
                                width: 65,
                                fixed: 'right',
                                render: (record) => (
                                    <div>
                                        <Row align="middle" justify="center" gutter={[5, 0]}>
                                            <Dropdown overlay={menuTable(record)} trigger={['click']}>
                                                <Button onClick={e => e.preventDefault()} icon={<MoreOutlined />} />
                                            </Dropdown>
                                        </Row>
                                    </div>
                                ),
                            }]
                        } rowSelection={rowSelection} scroll={{ x: 900 }} />
                    </div>
                    <ModalDetalheNotaFiscal exibirModal={openModalDetalhes} fecharModal={() => { setOpenModalDetalhes(false) }} dadosModal={dadosDetalhesNota} />
                    {/* <DrawerDetalheNotaFiscal openModal={openModalDetalhes} closeModal={() => { setOpenModalDetalhes(false) }} dadosDrawer={dadosModalNota} /> */}
                </div>
            }
            <DrawerPagamento formulario={formulario} totalizadores={{ listaTotalizadores, setListaTotalizadores }} calcularPagamentoParcial={calcularPagamentoParcial} listaParcelasPagar={{ listaParcelasPagar, setListaParcelasPagar }} limparListas={limparListas} dadosFornecedor={dadosFornecedor} />
        </div>
    );
}