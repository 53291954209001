import React, { useState } from "react";
import { Button, Form, Modal, Row, Col, Drawer } from "antd";
import { CloseOutlined, ExclamationCircleOutlined, MinusOutlined, SaveOutlined } from "@ant-design/icons";

import { useStateValue } from "../../state";
import { drawerActions, listagemActions, manutencaoActions } from "../../actions";
import ManutencaoServico from "./manutencao";

export default function DrawerServico({ editando }) {
    const [formServico] = Form.useForm();
    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [carregando, setCarregando] = useState(false);

    function onClose() {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação e ignorar o que foi realizado até o momento?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharDrawer();
            }
        })
    }

    function fecharDrawer() {
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: false } });
        dispatch({ type: listagemActions.CHANGE, data: { ordem: '+pro_descricao' } });
        editando.setEditando(false);
        setCarregando(false);
        formServico.resetFields();
    }

    return (
        <Drawer title="Cadastro Serviço"
            width="70%"
            visible={ui.showDrawer}
            closeIcon={null}
            limparAoEditar={true}
            footer={
                <div>
                    <Row align="middle" justify="end" gutter={[8, 0]}>
                        <Col>
                            <Button onClick={onClose} icon={<CloseOutlined />} size="large" htmlType="button">
                                Cancelar
                            </Button>
                        </Col>
                        <Col>
                            <Button onClick={() => formServico.submit()} loading={carregando} icon={<SaveOutlined />} size="large" type="primary" htmlType="submit">
                                Salvar
                            </Button>
                        </Col>
                    </Row>
                </div>
            }>
            <ManutencaoServico formulario={formServico} salvandoDados={setCarregando} aoSalvar={fecharDrawer} editando={editando.editando} />
        </Drawer>
    );
}