import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button, Tag, Popover, Modal, Radio } from "antd";
import { EditOutlined, DeleteOutlined, ExclamationCircleOutlined, CopyOutlined } from "@ant-design/icons";
import moment from 'moment';

import api from '../../services/api';
import { useStateValue } from '../../state';
import { FormatNumber } from '../../ValueObjects/';
import { drawerActions, listagemActions, manutencaoActions } from "../../actions";
import { PesquisaOrdenacao, TabelaDados, Editar, Excluir, BreadcrumbPage, BotaoIncluirDados, } from "../../components";
import DrawerServico from "./drawer";

export default function Servico() {
    const [{ manutencao, listagem }, dispatch] = useStateValue();
    const [formFiltros] = Form.useForm();
    const [formPesquisaAdc] = Form.useForm();
    const [editando, setEditando] = useState(false);

    useEffect(() => {
        let _ativo = !!!formFiltros.getFieldsValue().produtoAtivo ? 1 : formFiltros.getFieldsValue().produtoAtivo;
        dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: `&Ativo=${_ativo}`, filtro: '', ordem: '+pro_descricao' } });
        formFiltros.setFieldsValue({ produtoAtivo: 1 });
    }, [])

    function duplicarServico(servico) {
        let dados = {};
        if (!!servico.pro_id) {
            api.get(`produto/RetornaDadosProduto?ProdutoId=${servico.pro_id}`).then(res => {
                if (res.status === 200) {
                    dados = res.data;
                    dados.pro_id = null;
                    dados.pro_descricao = '';
                    dados.pro_datainclusao = '';
                    dados.data_sincro = '';
                    dispatch({ type: manutencaoActions.CHANGE, data: { dados: dados } });
                    dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
                }
            }).catch((erro) => console.error(erro));
        }
    };

    function showDrawer(servico) {
        let dados = {};
        if (!!servico.pro_id) {
            api.get(`produto/RetornaDadosProduto?ProdutoId=${servico.pro_id}`).then(res => {
                if (res.status === 200) {
                    dados = res.data;
                    dados.pro_datainclusao = !!dados.pro_datainclusao ? moment(dados.pro_datainclusao) : null;
                    dados.data_sincro = !!dados.data_sincro ? moment(dados.data_sincro) : null;
                    dados.orm_id = parseInt(dados.orm_id);
                }
            }).catch((erro) => console.error(erro))
                .finally(
                    () => {
                        dispatch({ type: manutencaoActions.CHANGE, data: { dados: dados } });
                        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
                    }
                );
        }
    };

    const modalExcluirServico = (servico) => {
        Modal.confirm({
            title: 'Excluir?',
            icon: <ExclamationCircleOutlined />,
            content: `Deseja realmente excluir o serviço ${servico.pro_codigo}-${servico.pro_descricao} ?`,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                excluirRegistro(servico);
            }
        });
    };

    function excluirRegistro(servico) {
        api.delete(`produto/excluir/${servico.pro_id}`).then((res) => {
            if (res.data == true) {
                dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
            } else {
                modalInativarServico(servico);
            }
        }, (erro) => {
            console.error(erro);
            modalInativarServico(servico);
        })
    };

    const modalInativarServico = (servico) => {
        Modal.confirm({
            title: 'Não foi possível excluir o serviço!',
            icon: <ExclamationCircleOutlined />,
            content: `Deve conter referências com outros registros,
            Deseja inativar o serviço ${servico.pro_codigo}-${servico.pro_descricao} ?`,
            okText: 'Inativar',
            cancelText: 'Cancelar',
            centered: true,
            onOk() {
                inativarServico(servico);
            }
        });
    };

    function inativarServico(servico) {
        api.put(`produto/AtivarInativar?IdProduto=${servico.pro_id}&Ativo=false`).then((res) => {
            if (res.status === 200)
                dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: '', filtro: '', ordem: '+pro_descricao' } });
        })
    };

    function retornoPesquisaAvancada(expressao) {
        listagem.outrosFiltros = '';
        expressao = !!expressao ? expressao : '';
        let filtrosTela = popularFiltrosTela();
        dispatch({ type: listagemActions.CHANGE, data: { filtro: !!listagem.filtro ? listagem.filtro : '', outrosFiltros: `${filtrosTela}&filtroAdicional=${btoa(expressao)}`, ordem: '+pro_descricao' } })
    };

    function popularFiltrosTela() {
        let filtrosTela = '';
        filtrosTela += `&Ativo=${formFiltros.getFieldsValue().produtoAtivo}`
        if (parseFloat(formPesquisaAdc.getFieldsValue().precoMinimo) > 0.00) filtrosTela += `&precoMinimo=${formPesquisaAdc.getFieldsValue().precoMinimo}`;
        if (parseFloat(formPesquisaAdc.getFieldsValue().precoMaximo) > 0.00) filtrosTela += `&precoMaximo=${formPesquisaAdc.getFieldsValue().precoMaximo}`;

        return filtrosTela;
    }

    function onClickEditar(servico) {
        setEditando(true);
        showDrawer(servico);
    };

    return (
        <div className="p-10">
            <Row align="middle" justify="space-between" className="col-pagina-breadcrumb">
                <Col className="col-w-100">
                    <BreadcrumbPage />
                </Col>
                <Col className="col-mob-100">
                    <Row align="middle" justify="end">
                        <Col>
                            <BotaoIncluirDados />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Form layout="vertical" name="formularioPesqAvancadaProdutos" form={formFiltros} onFinish={retornoPesquisaAvancada}>
                <Row gutter={[8, 0]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={14} xxl={16}>
                        <PesquisaOrdenacao opcoesOrdenacao={[
                            { label: "Nome A - Z", value: "+pro_descricao" },
                            { label: "Nome Z - A", value: "-pro_descricao" },
                            { label: "Código Crescente", value: "+pro_codigo" },
                            { label: "Código Decrescente", value: "-pro_codigo" }
                        ]} />
                    </Col>
                    <Col xs={24} sm={24} md={9} lg={9} xl={6}>
                        <Form.Item label="Selecione Status do Serviço" name="produtoAtivo">
                            <Radio.Group onChange={() => retornoPesquisaAvancada()}>
                                <Radio value={2}>Todos</Radio>
                                <Radio value={1}>Ativo</Radio>
                                <Radio value={0}>Inativo</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <div className="tabela">
                <TabelaDados url="produto/ListarTodas?SomenteServico=true&" colunas={[
                    {
                        title: 'Código',
                        width: 75,
                        align: 'center',
                        render: ({ pro_codigo, pro_ativo }) => (
                            <Popover content={pro_ativo ? 'Ativo' : 'Inativo'} placement="right">
                                <Tag color={pro_ativo ? 'processing' : 'default'} className="w-100">
                                    <b>{pro_codigo}</b>
                                </Tag>
                            </Popover>
                        ),
                    },
                    {
                        title: 'Descrição do Serviço',
                        render: ({ pro_descricao }) => (
                            <div>
                                <b>{pro_descricao}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Tipo de Serviço',
                        render: ({ csv_descricao }) => (
                            <div>
                                <b>{csv_descricao || 'Não Informado'}</b>
                            </div>
                        )
                    },
                    {
                        title: 'Tributação Pis/Confins',
                        width: 150,
                        render: ({ pis_descricao }) => (
                            <div>
                                <b>{pis_descricao || 'Não Informado'}</b>
                            </div>
                        )
                    },
                    {
                        title: 'Un. Medida',
                        width: 100,
                        render: ({ ump_descricao }) => (
                            <div>
                                <b>{ump_descricao}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Preço (R$)',
                        width: 120,
                        align: 'right',
                        render: ({ precoVendaMinimo, precoVendaMaximo }) => (
                            <div>
                                {precoVendaMinimo === precoVendaMaximo &&
                                    <b className="f-18" >{FormatNumber(precoVendaMinimo, true)}</b>
                                }
                                {precoVendaMinimo !== precoVendaMaximo &&
                                    <b className="f-18" >{FormatNumber(precoVendaMinimo, true)} até {FormatNumber(precoVendaMaximo, true)}</b>
                                }
                            </div>
                        ),
                    },
                    {
                        title: 'Ações',
                        dataIndex: '',
                        key: 'x',
                        align: 'center',
                        width: 95,
                        fixed: 'right',
                        render: (servico) => (
                            <div>
                                <Row align="middle" justify="center" gutter={[5, 0]}>
                                    <Col>
                                        <Button icon={<CopyOutlined />} onClick={() => { duplicarServico(servico) }} />
                                    </Col>
                                    <Col>
                                        <Editar onClick={() => onClickEditar(servico)} icon={<EditOutlined />} />
                                    </Col>
                                    <Col>
                                        <Excluir icon={<DeleteOutlined />} onClick={() => modalExcluirServico(servico)} />
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                ]} />
            </div>
            <DrawerServico editando={{ editando, setEditando }} />
        </div>
    )
}