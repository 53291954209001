import React, { useState, useEffect } from "react";
import { Row, Col, Button, Menu, Checkbox, Form, Dropdown, Table } from "antd";
import { ArrowLeftOutlined, FileDoneOutlined, EyeOutlined, DollarCircleOutlined, MoreOutlined, DollarOutlined, SyncOutlined } from "@ant-design/icons";
import moment from 'moment';

import api from '../../services/api';
import { useStateValue } from '../../state';
import { FormatNumber } from '../../ValueObjects';
import DrawerReparcelamento from './drawerReparcelamento';
import DrawerDetalheNotaFiscal from './drawerDetalheNotaFiscal';

import { drawerActions, manutencaoActions } from "../../actions";
import { compararDatas, diferencaDatas } from "../../services/funcoes";
import ModalDetalheNotaFiscal from "../notaFiscal/modalDetalheNotaFiscal";

export default function ListaParcelasCliente({ selectedRowKeys, setSelectedRowKeys, selecionados, setListaParcelasCliente, receberSelecionados, listaParcelasReceber, setDadosCliente, dadosCliente }) {
    const [{ manutencao, parametrosEmpresa }, dispatch] = useStateValue();
    const [listaParcelas, setListaParcelas] = useState([]);
    const [listaParcelasReparcelar, setListaParcelasReparcelar] = useState([]);
    const [listaTotalizadoresReparcelar, setListaTotalizadoresReparcelar] = useState({});
    const [openModalDetalhes, setOpenModalDetalhes] = useState(false);
    const [dadosModalNota, setDadosModalNota] = useState({});
    const [dadosDetalhesNota, setDadosDetalhesNota] = useState({});

    const rowSelection = {
        selectedRowKeys,
        onChange: (key, selectedRow) => {
            somarParcelas(key);
        }
    };

    useEffect(() => {
        buscarParcelasPessoa();
    }, []);

    function somarParcelas(keys) {
        let totais = [];
        totais.quantidade = keys.length;
        totais.valorOriginal = 0;
        totais.juros = 0;
        totais.multa = 0;
        totais.total = 0;

        keys.forEach((key) => {
            let parcela = listaParcelas.filter((item) => { return item.key === key })[0];
            totais.valorOriginal += parcela.valorOriginal;
            totais.juros += parcela.valorJuros;
            totais.multa += parcela.valorMulta;
            totais.total += parcela.valorPagoRecebido;
        });
        selecionados.setTotalSelecionados(totais);
        setSelectedRowKeys(keys);
    };
    async function visualizarNota(dados) {
        let retorno = [];
        let vendedor = [];
        if (!!dados.ntf_id) {
            retorno = await api.get(`NotaFiscal/ListarNotaFiscal?IdNotaFiscal=${dados.ntf_id}`);
            if (retorno.status === 200) {
                retorno = retorno.data;
                if (!!retorno.ven_id) {
                    vendedor = await api.get(`Vendedor/RetornaVendedor?idVendedor=${retorno.ven_id}`);
                    if (vendedor.status === 200) {
                        retorno.nomeVendedor = vendedor.data.pes_nome;
                    }
                }
                retorno.itensNf.forEach((item) => {
                    let totalItemSemDesconto = parseFloat((item.nfi_qtde * item.nfi_valorunitario)).toFixed(2);
                    item.perc_desconto = parseFloat((item.nfi_valordesconto / (totalItemSemDesconto)) * 100).toFixed(2);
                    let valorDesconto = item.nfi_valordesconto;
                    item.valor_item_desconto = parseFloat(totalItemSemDesconto - valorDesconto).toFixed(2);
                });
                setDadosDetalhesNota(retorno);
                setOpenModalDetalhes(true);
            }
        }
    };

    function buscarParcelasPessoa() {
        if (!!dadosCliente.pes_id) {
            api.get(`Parcela/ListarParcelas?PagarOuReceber=1&IdPessoa=${dadosCliente.pes_id}&StatusParcela=0,1&Estornado=false&PageSize=1000&Order=prc_datavencimento`).then(res => {
                listarParcelas(res);
            })
        }
    };

    function selecionarVencidas(e) {
        let keys = [];
        if (e.target.checked) {
            listaParcelas.map((item) => {
                if (compararDatas(item.prc_datavencimento, null, '<')) {
                    keys.push(item.key);
                }
            });
        }
        somarParcelas(keys);
    };

    function listarParcelas(dados) {
        let totalReceber = 0;
        let parcelasReceber = [];
        let keys = [];
        let items = dados.data.items;
        if (listaParcelasReceber.length > 0) {
            listaParcelasReceber.map((parcela) => {
                keys.push(parcela.key);
            });
            setSelectedRowKeys(keys);
        }
        items.forEach((item) => {
            let valorReceber;
            item.vencida = compararDatas(item.prc_datavencimento, new Date(), '<');
            item.numeroDiasVencida = 0;
            item.valorJuros = 0;
            item.valorMulta = 0;
            item.key = item.cpr_id.toString().concat(item.prc_id);
            item.zerouJuros = false;
            item.zerouMulta = false;
            if (item.vencida) {
                item.numeroDiasVencida = diferencaDatas(item.prc_datavencimento);
                if (!(!!parametrosEmpresa.par_diastoleranciacobrancajuro)) {
                    valorReceber = parseFloat((item.prc_valor - item.valorQuitado).toFixed(2));
                    item.valorJuros = parseFloat((((valorReceber * (parametrosEmpresa.par_percjuromesparcelavencida / 30)) / 100) * item.numeroDiasVencida).toFixed(2));

                    if(parametrosEmpresa.par_tipocalculomulta == 1){
                        item.valorMulta= !!parametrosEmpresa.par_multaparcelavencida ? parametrosEmpresa.par_multaparcelavencida : 0;
                    } else if(parametrosEmpresa.par_tipocalculomulta == 2){
                        item.valorMulta= !!parametrosEmpresa.par_multaparcelavencida ? (item.prc_valor * ( parametrosEmpresa.par_multaparcelavencida/100)) : 0;
                    } 
                } else if (item.numeroDiasVencida > parametrosEmpresa.par_diastoleranciacobrancajuro) {
                    valorReceber = parseFloat((item.prc_valor - item.valorQuitado).toFixed(2));
                    item.valorJuros = parseFloat((((valorReceber * (parametrosEmpresa.par_percjuromesparcelavencida / 30)) / 100) * item.numeroDiasVencida).toFixed(2));

                    if(parametrosEmpresa.par_tipocalculomulta == 1){
                        item.valorMulta= !!parametrosEmpresa.par_multaparcelavencida ? parametrosEmpresa.par_multaparcelavencida : 0;
                    } else if(parametrosEmpresa.par_tipocalculomulta == 2){
                        item.valorMulta= !!parametrosEmpresa.par_multaparcelavencida ? (item.prc_valor * ( parametrosEmpresa.par_multaparcelavencida/100)) : 0;
                    } 
                }
            }
            item.valorOriginal = parseFloat(item.prc_valor);
            item.prc_valor = parseFloat(((item.prc_valor - item.valorQuitado) + item.valorJuros + item.valorMulta).toFixed(2) );
            item.valorPagoRecebido = parseFloat(item.prc_valor);
            item.valorDesconto = 0.00;
            item.valorAReceber = item.prc_valor;
            totalReceber += parseFloat((item.valorPagoRecebido).toFixed(2));
            parcelasReceber.push(item);
        });
        parcelasReceber.totalReceber = totalReceber;
        setListaParcelas(parcelasReceber);
        setListaParcelasReparcelar([]);
        setListaTotalizadoresReparcelar({});
    };

    function menuTable(dados) {
        return (
            <Menu className="text-right">
                <Menu.Item key="1">
                    <Button type="text" icon={<FileDoneOutlined />} onClick={() => {
                        let keys = [...selectedRowKeys];
                        if (selectedRowKeys.indexOf(dados.key) < 0) {
                            keys.push(dados.key);
                            somarParcelas(keys);
                        }
                        receberSelecionados(listaParcelas, keys);
                    }}> Quitar Parcela </Button>
                </Menu.Item>
                <Menu.Item key="2">
                    <Button type="text" icon={<EyeOutlined />} onClick={() => {
                        visualizarNota(dados);
                        //showDrawerExtra(dados);
                    }}> Detalhes da Nota </Button>
                </Menu.Item>
                <Menu.Item key="3">
                    <Button type="text" icon={<DollarCircleOutlined />} onClick={() => {
                        let keys = [...selectedRowKeys];
                        if (selectedRowKeys.indexOf(dados.key) < 0) {
                            keys.push(dados.key);
                            setSelectedRowKeys(keys);
                        }
                        reparcelarSelecionados(listaParcelas, keys);
                    }}> Reparcelar Parcela </Button>
                </Menu.Item>
            </Menu>
        )
    };

    function reparcelarSelecionados(listaParcelas, selectedRowKeys) {
        let listaTemp = [];

        listaParcelas.map(parcela => {
            if (selectedRowKeys.indexOf(parcela.key) >= 0) {
                listaTemp.push(parcela);
            }
        });

        let lista = {};
        lista.valorTotalJuros = 0;
        lista.valorTotalMulta = 0;
        lista.valorDesconto = 0;
        lista.valorAReceber = 0;
        if (listaTemp.length > 0) {
            listaTemp.map(item => {
                let valorTotal = parseFloat((!!item.valorOriginal ? item.valorOriginal : 0)) - parseFloat((!!item.valorQuitado ? item.valorQuitado : 0));
                lista.valorDesconto += parseFloat(item.valorDesconto);
                lista.valorAReceber += parseFloat(valorTotal - parseFloat(item.valorDesconto) + parseFloat(item.valorJuros) + parseFloat(item.valorMulta));
            });
        }
        lista.valorDesconto = parseFloat(lista.valorDesconto.toFixed(2));
        lista.valorAReceber = parseFloat(lista.valorAReceber.toFixed(2));
        setListaTotalizadoresReparcelar(lista);

        setListaParcelasReparcelar(listaTemp);
        dispatch({ type: drawerActions.CHANGE, data: { showDrawerReparcelamento: true } })
    };

    async function showDrawerExtra(dados) {
        let retorno = [];
        let vendedor = [];
        if (!!dados.ntf_id) {
            retorno = await api.get(`NotaFiscal/ListarNotaFiscal?IdNotaFiscal=${dados.ntf_id}`);
            if (retorno.status === 200) {
                retorno = retorno.data;
                if (!!retorno.ven_id) {
                    vendedor = await api.get(`Vendedor/RetornaVendedor?idVendedor=${retorno.ven_id}`);
                    if (vendedor.status === 200) {
                        retorno.nomeVendedor = vendedor.data.pes_nome;
                    }
                }
                retorno.itensNf.forEach((item) => {
                    let totalItemSemDesconto = parseFloat((item.nfi_qtde * item.nfi_valorunitario)).toFixed(2);
                    item.perc_desconto = parseFloat((item.nfi_valordesconto / (totalItemSemDesconto)) * 100).toFixed(2);
                    let valorDesconto = item.nfi_valordesconto;
                    item.valor_item_desconto = parseFloat(totalItemSemDesconto - valorDesconto).toFixed(2);
                });

                setDadosModalNota(retorno);
                setOpenModalDetalhes(true);
            }
        }
    };

    return (
        <div>
            <Form layout="vertical">
                <Row align="middle" justify="space-between" gutter={[8, 0]}>
                    <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                        <Button icon={<ArrowLeftOutlined />} htmlType="submit" onClick={() => {
                            setListaParcelasCliente(false);
                            if (listaParcelasReceber.length === 0) {
                                setDadosCliente({});
                            }
                        }}>
                            Voltar
                        </Button>
                    </Col>
                    <Col className="text-center" xs={24} sm={12} md={8} lg={8} xl={8}>
                        Parcelas em Aberto do cliente<br />
                        <b className="f-16">{dadosCliente.pes_nome}</b>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                        <div className="ant-input-number-input p-0">
                            Total à receber do cliente<br />
                            <b className="f-16">{FormatNumber(!!listaParcelas.totalReceber ? listaParcelas.totalReceber : 0, true)}</b>
                        </div>
                    </Col>
                </Row>
            </Form>
            <div className="tabela m-t-8 p-b-75">
                <Row align="middle" gutter={[8, 0]} justify="space-between">
                    <Col xs={24} sm={24} md={8} lg={10} xl={12}>
                        <Checkbox onChange={selecionarVencidas}>
                            Selecionar Vencidas
                        </Checkbox>
                    </Col>
                    <Col xs={24} sm={24} md={16} lg={14} xl={12}>
                        <Row align="middle" justify="end" gutter={[8, 8]} className="m-t-5 m-b-5">
                            <Col xs={24} sm={12} md={12} lg={12} xl={10} xxl={8}>
                                <Button disabled={selectedRowKeys.length === 0} onClick={() => { reparcelarSelecionados(listaParcelas, selectedRowKeys); }} icon={<SyncOutlined />} block htmlType="submit">
                                    Reparcelar Todos os Selecionados
                                </Button>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={12} xl={10} xxl={8}>
                                <Button disabled={selectedRowKeys.length === 0} onClick={() => receberSelecionados(listaParcelas, selectedRowKeys)} icon={<DollarOutlined />} type="primary" block htmlType="button">
                                    Receber Todos os Selecionados
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Table dataSource={listaParcelas} columns={
                    [
                        {
                            title: 'Nº Doc.',
                            width: 130,
                            render: ({ cpr_numerodocumento }) => (
                                <div>
                                    <b>{cpr_numerodocumento || '--'}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Nº Parcela',
                            align: 'left',
                            width: 75,
                            render: ({ prc_numeroparcela }) => (
                                <div>
                                    <b>{prc_numeroparcela}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Emissão',
                            align: 'center',
                            width: 115,
                            render: ({ cpr_datainclusao }) => (
                                <div>
                                    <b>{moment(cpr_datainclusao).format('DD/MM/YYYY')}</b>
                                </div>
                            ),
                        }, {
                            title: 'Vencimento',
                            align: 'center',
                            width: 115,
                            render: ({ prc_datavencimento, vencida }) => (
                                <div>
                                    <b className={vencida ? "c-red" : ""}>{moment(prc_datavencimento).format('DD/MM/YYYY')}</b>
                                </div>
                            ),
                        }, {
                            title: 'Dias Venc.',
                            width: 85,
                            align: 'right',
                            render: ({ diasAtrazo, vencida }) => (
                                <div>
                                    <b className={vencida ? "c-red" : ""}>{diasAtrazo}</b>
                                </div>
                            ),
                        }, {
                            title: 'Valor Original (R$)',
                   
                            align: 'right',
                            render: ({ valorOriginal }) => (
                                <div>
                                    <b>
                                        {FormatNumber(!!valorOriginal ? valorOriginal : 0, true)}
                                    </b>
                                </div>
                            ),
                        }, {
                            title: 'Juros (R$)',
                            align: 'right',
                            render: ({ valorJuros }) => (
                                <div>
                                    <b className={!!valorJuros && parseFloat(valorJuros) > 0 ? 'c-red' : ''}>
                                        {FormatNumber(!!valorJuros ? valorJuros : 0, true)}
                                    </b>
                                </div>
                            ),
                        },{
                            title: 'Multa (R$)',
                            align: 'right',
                            render: ({ valorMulta }) => (
                                <div>
                                    <b className={!!valorMulta && parseFloat(valorMulta) > 0 ? 'c-red' : ''}>
                                        {FormatNumber(!!valorMulta ? valorMulta : 0, true)}
                                    </b>
                                </div>
                            ),
                        }, {
                            title: 'Recebido (R$)',
                            align: 'right',
                            render: ({ valorQuitado }) => (
                                <div>
                                    <b>
                                        {FormatNumber(!!valorQuitado ? valorQuitado : 0, true)}
                                    </b>
                                </div>
                            ),
                        }, {
                            title: 'A Receber (R$)',
                            align: 'right',
                            render: ({ valorPagoRecebido }) => (
                                <div>
                                    <b>
                                        {FormatNumber(!!valorPagoRecebido ? valorPagoRecebido : 0, true)}
                                    </b>
                                </div>
                            ),
                        },
                        {
                            title: 'Ações',
                            align: 'center',
                            width: 65,
                            fixed: 'right',
                            render: (record) => (
                                <div>
                                    <Dropdown overlay={menuTable(record)} trigger={['click']}>
                                        <Button onClick={e => e.preventDefault()} icon={<MoreOutlined />} />
                                    </Dropdown>
                                </div>
                            ),
                        }
                    ]
                } rowSelection={rowSelection} scroll={{ x: 900 }} />
            </div>
            <div className="ant-drawer-footer footer-recebimento">
                <Row align="middle" justify="center" gutter={[8, 0]}>
                    <Col xs={24} sm={12} md={6} lg={6} xl={6} className="rg-t">
                        <span className="f-14">Quantidade: </span>
                        <b className="f-16">{!!selecionados.totalSelecionados.quantidade ? selecionados.totalSelecionados.quantidade : 0}</b>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6} xl={6} align="right">
                        <span className="f-14">Original (R$): </span>
                        <b className="f-16">{FormatNumber(!!selecionados.totalSelecionados.valorOriginal ? selecionados.totalSelecionados.valorOriginal : 0, true)}</b>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6} xl={6} align="right">
                        <span className="f-14">Juros (R$): </span>
                        <b className="f-16">{FormatNumber(!!selecionados.totalSelecionados.juros ? selecionados.totalSelecionados.juros : 0, true)}</b>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6} xl={6} align="right">
                        <span className="f-14">Multa (R$): </span>
                        <b className="f-16">{FormatNumber(!!selecionados.totalSelecionados.multa ? selecionados.totalSelecionados.multa : 0, true)}</b>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6} xl={6} align="right">
                        <span className="f-14">Total (R$): </span>
                        <b className="f-16">{FormatNumber(!!selecionados.totalSelecionados.total ? selecionados.totalSelecionados.total : 0, true)}</b>
                    </Col>
                </Row>
            </div>
            <ModalDetalheNotaFiscal exibirModal={openModalDetalhes} fecharModal={() => { setOpenModalDetalhes(false) }} dadosModal={dadosDetalhesNota} />
            <DrawerReparcelamento listaParcelasReparcelar={listaParcelasReparcelar} totalizadores={{ listaTotalizadoresReparcelar, setListaTotalizadoresReparcelar }} dadosCliente={dadosCliente} buscarParcelasPessoa={buscarParcelasPessoa} />
        </div>
    )
}